import { mapGetters } from 'vuex';
import { mapMutations } from 'vuex';

import {changeDay} from "@/main";
import api from '@/api';

export default {
    extends: api,
    props: ['moreInfo','urlExperience','multiBooking','showExperienceName','showRoomName','callSunday','call'],
    data() {
        return {
            noExperience: false,
            experiencies: []
        }
    },
    computed: {
        ...mapGetters(['getDateSelected', 'getExperiences','isMultiPrice']),
        transformDate() {
            const dateSelected = this.getDateSelected().split('/');
            const newDateSelected = new Date(dateSelected[2], dateSelected[1]-1, dateSelected[0], 0);
            const monthName = newDateSelected.toLocaleString('es-ES', { month: 'long' });

            return `${newDateSelected.getDate()} DE ${monthName}`;
        }
    },
    created() {
        this.getExperiencies();
    },
    methods: {
        ...mapMutations(['updateExperiences','updateCheckout','updateIsMultiplePrice','updatePlayersSelected']),
        getExperiencies: function () {
            let experiencePrommise = new Promise((resolve) => {
                this.getExperiencesByIdApi(resolve);
            });
            experiencePrommise.then((experiences) => {
                this.experiencies = experiences;
                this.noExperience = experiences.length === 0;

                this.updateIsMultiplePrice({experiences: experiences});
                this.updateExperiences({experiences: experiences});
                this.$emit('load-experience');
            });
        },
        onExperienceSelected: function () {
            this.$emit('next-step');
        },
        dayBeforeHeader: function () {
            changeDay.$emit('day-before');
        },
        dayAfterHeader: function() {
            changeDay.$emit('day-after');
        },
        onSelectPlayers(playersSelected){
            this.updatePlayersSelected(playersSelected);
            this.getExperiencies();
        }
    }
}