<template>
    <div class="form-group" v-bind:class="{'dark': this.getDesign() == 'black', 'white' : this.getDesign() == 'white'}">
        <label>{{ $t(this.field.name) }} <span v-if="field.isRequired">*</span></label>
        <select v-model="idSelectedField" :required="field.isRequired" @change="changeField" v-bind:class="{'required-field': field.isRequired}">
            <option v-if="field.isRequired" selected> </option>
            <option :value="index" v-for="(option, index) in this.field.options" v-bind:key="index">{{ getOptionName(option) }}</option>
        </select>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { mapMutations } from 'vuex';

    export default {
        name: "select",
        props: ["field","experience","room","start"],
        data() {
            return {
                idSelectedField: null,
            }
        },
        computed: {
            ...mapGetters(['getDesign','getCheckout','getFieldItems','isMultiPrice'])
        },
        created() {
            const fieldTemp = this.getFieldItems({id_experience: this.experience, id_calendario : this.room.id_calendario,  start: this.start, title: this.field.name});
            if(fieldTemp) {
                this.idSelectedField = this.getPositionValue(fieldTemp.value);
            }
            else if (!this.field.isRequired) {
                this.idSelectedField = 0;
            }
            this.changeField();
        },
        methods: {
            ...mapMutations(['updateItemField']),
            getPositionValue: function (value) {
                for (let i = 0; i < this.field.options.length; i += 1) {
                    if (this.field.options[i].value === value) {
                        return i;
                    }
                }
            },
            changeField: function () {
                const parameters = this.idSelectedField != null && this.field.options[this.idSelectedField]
                ? this.field.options[this.idSelectedField] 
                : {value: null, name: this.field.name, price: 0};
                
                if(this.isMultiPrice()) {
                    const experienceItems = this.getCheckout().items.filter((item) => item.id_experience === this.experience);
                    experienceItems.forEach(item => {
                        const newField =  {
                            id: this.$uuid.v4(this.field.id),
                            name: parameters.name,
                            title: this.field.name,
                            value: parameters.value ? parameters.value : '',
                            amount: parameters.price,
                            total: parameters.name.includes('*') ? Number(parameters.price) * item.people_number : parameters.price
                        };
                        this.updateItemField({id_experience: this.experience, id_calendario : item.id_calendario, newField, start: this.start})
                    })
                }else {
                    const newField =  {
                        id: this.$uuid.v4(this.field.id),
                        name: parameters.name,
                        title: this.field.name,
                        value: parameters.value ? parameters.value : '',
                        amount: parameters.price,
                        total: parameters.name.includes('*') ? Number(parameters.price) * this.room.people_number : parameters.price
                    };

                    this.updateItemField({id_experience: this.experience, id_calendario : this.room.id_calendario, newField, start: this.start});
                }

                if(this.getCheckout().id) {
                    this.$emit('change-field');
                }
            },
            getOptionName: function(option) {
                if(option.name.includes('*')){
                    return `${option.name.replace('*','')} por persona`;
                }

                return option.name
            }
        }
    }
</script>
