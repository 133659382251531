<template>
    <div id="form-general">
        <div v-for="(field, index) in item.fields" class="form-group" v-bind:key="index">
            <div v-if="field.name === 'Nombre' && field.isActive && field.isBasic">
                <input-general-name :field="field" @change-field="changeField"/>
            </div>
            <div v-if="field.name === 'Telefono' && field.isActive && field.isBasic">
                <input-general-phone :field="field" @change-field="changeField"/>
            </div>
            <div v-if="field.name === 'Correo' && field.isActive && field.isBasic">
                <input-general-mail :field="field" @change-field="changeField"/>
            </div>
            <div v-if="field.name === 'DNI' && field.isActive && field.isBasic">
                <input-general-dni :field="field" @change-field="changeField"/>
            </div>
            <div v-if="field.name === 'Direccion' && field.isActive && field.isBasic">
                <input-general-direction  :field="field" @change-field="changeField"/>
            </div>
        </div>
    </div>
</template>

<script>
    import InputGeneralName from './input-components/input-general-name'
    import InputGeneralPhone from './input-components/input-general-phone'
    import InputGeneralMail from './input-components/input-general-mail'
    import InputGeneralDni from './input-components/input-general-dni'
    import InputGeneralDirection from './input-components/input-general-direction'

    import formGeneral from './form-general.js'

    export default {
        extends: formGeneral,
        name: "form-general",
        components: {
            InputGeneralName,
            InputGeneralPhone,
            InputGeneralMail,
            InputGeneralDni,
            InputGeneralDirection
        }
    }
</script>