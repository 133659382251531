<template>
    <div id="step-two" v-if="loading" class="minimal-step-two" v-bind:class="{'dark': this.getDesign() == 'black', 'white' : this.getDesign() == 'white'}">
        <form @submit="checkForm" >
            <header-experience @back-button="backButton" v-if="getCheckout().items.length"/>
            <div v-if="showCheckoutStepOne">
                <div class="box-checkout-content">
                    <form-general />
                    <div class="form-group-order">
                        <div v-for="(field,index) in getFields(experience)" v-bind:class="`${field.type}-form-group`" v-bind:key="index">
                            <input-normal v-if="field.type === 'input'" :experience="experience.id" :start="room.start" :room="room.id_calendario"  :field="field"  @change-field="changeField"/>
                            <select-normal v-if="field.type === 'select' && !field.isLanguage" :experience="experience.id" :start="room.start" :room="room" :field="field" @change-field="changeField"/>
                            <checkbox-normal v-if="field.type === 'checkbox'" :experience="experience.id" :start="room.start" :room="room.id_calendario" :field="field"  @change-field="changeField"/>
                            <text-area-normal v-if="field.type === 'textarea'" :experience="experience.id" :start="room.start" :room="room.id_calendario" :field="field" @change-field="changeField"/>
                        </div>
                    </div>
                    <div id="general-conditions-content">
                        <div class="form-group form-group-checkbox"  v-bind:class="{'dark': this.getDesign() == 'black', 'white' : this.getDesign() == 'white'}">
                            <label for="conditions_check">{{ $t('readTerms') }} <a target="_blank" :href="terms">{{ $t('readTerms2') }}</a></label>
                            <input id="conditions_check" name="conditions_check" type="checkbox" @click="createNewPurchase" v-model="checkedConditions" required>
                        </div>
                    </div>
                    <div id="resume-step-one-checkout">
                        <div class="left-part">
                            <p>Fecha: <span>{{ getDateSelected() }}</span></p>
                            <p>Hora: <span>{{ roomHour(getCheckout().items[0]) }}</span></p>
                            <p>Personas: <span>{{ totalPeople }}</span></p>
                        </div>
                        <div class="right-part">
                            <p @click="backButton">{{ $t('modify') }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <resume :key="resumeKey" :call-sunday="callSunday" @change-payment-method="changePaymentMethod"/>
            </div>
        </form>
        <div class="form-group-price" v-if="showCheckoutStepOne">
            <div class="price-number">
                <span>Precio:</span>
                <span>{{ getTotalPriceCalcutate }}€</span>
            </div>
            <div class="button button-flat" v-if="getCheckout().items[0]?.start && checkedConditions" @click="nextStep">Siguiente</div>
        </div>

        <div v-if="showFormsPayments">
            <stripe-component :key="stripeKey" :paymentMethod="paymentMethod" v-if="paymentMethodId === 3"/>
            <form v-if="paymentMethod" style="display: none;" id="final-form"> </form>
        </div>
    </div>
</template>

<script>
    import FormGeneral from './form-general/form-general.vue';
    import Resume from './resume/resume.vue';
    import StripeComponent from './stripe-component/stripe-component.vue'
    import HeaderExperience from './header-experience/header-experience.vue'
    import SelectNormal from './form-experience/input-components/select-normal.vue'
    import InputNormal from './form-experience/input-components/input-normal'

    import checkout from './checkout';

    export default {
        extends: checkout,
        name: 'minimal-checkout',
        components: {
            FormGeneral,
            Resume,
            StripeComponent,
            HeaderExperience,
            SelectNormal,
            InputNormal
        },
        data() {
            return {
                experience: {},
                room: {}
            }
        },
        methods: {
            getFields(){
                this.experience = this.getExperiences().find(experience => experience.id === this.getCheckout().items[0].id_experience);
                this.room = this.getCheckout().items.find((item) => item.id_experience === this.experience.id);
                return this.experience.fields.filter(f => f.isActive && !f.isBasic);
            },
            changeField: function () {
                if(this.getCheckout().id) {
                    let updatePurchasePrommise = new Promise((resolve) => {
                        this.updatePurchaseApi(resolve);
                    });
                    updatePurchasePrommise.then((purchase) => {
                        this.updateCheckout({checkout: purchase});
                    });
                }
            }
        }
    };
</script>

<style lang="scss" scoped>

    @import "@/scss/_variables.scss";
    @import "@/scss/_variables_white.scss";
    @import "@/scss/_variables_dark.scss";

    @import "checkout";
</style>