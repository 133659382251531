<template>
    <div id="line-resume-content">
        <div class="line-resume" v-for="(room, index) in getItems" v-bind:key="index">
            <p class="title-line-resume">
                {{ experenceById(room.id_experience).name }} <br />
                {{ getDateSelected() }} {{ $t('a las') }} {{ roomHour(room) }}
            </p>
            <div class="subtitle-line-resume">
                <p>- {{ room.people_number }} {{ $t('personas') }} </p>
                <p>{{ room.price_original }}€</p>
            </div>
            <div class="subtitle-line-resume" v-for="(field, index) in getFields(room)" v-bind:key="index">
                <p>- {{ field.title }} : {{ field.value }}</p>
                <p>{{ field.amount }}€</p>
            </div>
            <div v-if="room.discount_amount > 0" class="resume-inline-discount">
                <p>Código {{ getCheckout().code }}</p>
                <p>- {{ Number(room.discount_amount).toFixed(2) }}€</p>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: "line-resume-content",
        computed: {
            ...mapGetters(['getCheckout', 'getExperiences', 'getDateSelected','isMultiPrice','getPlayersSelected','isMinimal']),
            roomHour: function () {
                return (room) => {
                    const date = room.start.includes('T') ? new Date(room.start.replace(' ', 'T')) : new Date(room.start);
                    const hours = date.getHours().toString().padStart(2, '0');
                    const minutes = date.getMinutes().toString().padStart(2, '0');
                    return `${hours}:${minutes}h`;
                };
            },
            experenceById: function () {
                return (id_experience) => {
                    return this.getExperiences().filter((experience) => { return experience.id === id_experience })[0]
                }
            },
            getItems: function() {
                let items = this.getCheckout().items;
                if(this.isMultiPrice()){
                    const item = {...items[0]};
                    if(this.isMinimal()){
                        item.people_number = items.reduce((accumulator, currentItem) => accumulator + currentItem.people_number, 0);
                    }else{
                        item.people_number = item.total_players || this.getPlayersSelected();
                    }
                    item.price_original = items.reduce((accumulator, currentItem) => accumulator + Number(currentItem.price_original), 0);
                    item.discount_amount = items.reduce((accumulator, currentItem) => accumulator + Number(currentItem.discount_amount), 0);
                    items = [item];
                }

                return items;
            }
        },
        methods: {
            getFields: function(room) {
                const experience = this.experenceById(room.id_experience);
                let playersSelected = this.getPlayersSelected();
                playersSelected = playersSelected > 0 ? playersSelected : room.people_number;
                let fields = room.fields.filter(f => f.amount > 0);
                let fieldsResponse = [];
                fields = fields.map(field => {
                    let amount = field.amount * Math.ceil(playersSelected / experience.maxPlayers);
                    let value = `${field.value} ${amount}€`;

                    if(field.name.includes('*')) {
                        value = `${value} por persona`;
                        amount = field.amount * playersSelected;
                    }

                    fieldsResponse.push({
                        ...field,
                        value,
                        amount
                    });

                    return field;
                })

                return fieldsResponse.length > 0 ? fieldsResponse : fields;
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "@/scss/_variables.scss";
    @import "@/scss/_variables_white.scss";
    @import "@/scss/_variables_dark.scss";

    #aion-checkout {
        #line-resume-content {
            .line-resume {
                width: calc(100% - 12px);
                margin-left: 12px;
                .title-line-resume {
                    color: $primary-color-text-white;
                    text-transform: uppercase;
                    font-size: 22px;
                    line-height: 24px;
                    margin-bottom: 20px;
                }
                .subtitle-line-resume {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    margin-bottom: 15px;
                    margin-left: 20px;
                    * {
                        letter-spacing: 3px;
                        color: $primary-color-text-white;
                    }
                    p {
                        text-transform: initial;
                        font-family: 'DIN NextLTPro Condensed', SansSerif;
                        font-size: 18px;
                        line-height: 22px;
                        span {
                            display: inline-block;
                            margin-left: 13px;
                        }
                    }
                }
                .resume-inline-discount {
                    margin-top: 25px;
                    margin-bottom: 15px;
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    color: $green-color;
                }
            }
        }

        .multi-price{
            #line-resume-content {
                .line-resume {
                    .title-line-resume {
                        font-size: 19px;
                        line-height: 20px;
                    }
                    .subtitle-line-resume,
                    .resume-inline-discount {
                        font-size: 16px;
                        margin-left: 0;
                        p {
                            font-size: 16px;
                        }
                    }
                }
            }
        }

        @media screen and (max-width: 1024px) {
            #line-resume-content {
                .line-resume {
                    .title-line-resume {
                        font-size: 19px;
                        line-height: 20px;
                    }
                    .subtitle-line-resume,
                    .resume-inline-discount {
                        font-size: 16px;
                        margin-left: 0;
                        p {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
</style>
